
// import { useState } from "react";
// import { logo } from "../assets";
// import { baseUrl, primaryColor, secondaryColor } from "../constants/constants";
// import { MainButton } from "../components";
// import { NavLink } from "react-router-dom";
// import VerifyOtp from "./VerifyOtp";
// import FormSideUi from "../components/formSideUi";


// const ForgetPassword = () => {
//   const [email, setEmail] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [error, setError] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [step, setStep] = useState(0);

//   const onButtonClick = () => {
//     if (isLoading) return; // Prevent further clicks while loading

//     setIsLoading(true); // Set loading to true when button is clicked
//     setEmailError(""); // Reset email error
//     setError(""); // Reset general error

//     // Validation for email field
//     if ("" === email) {
//       setEmailError("The email field is required");
//       setIsLoading(false); // Reset loading state after validation failure
//       return;
//     }
    
//     if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
//       setEmailError("Please enter a valid email");
//       setIsLoading(false); // Reset loading state after validation failure
//       return;
//     }

//     // Make the API request to send OTP
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       email: email,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     // API call to send OTP
//     fetch(`${baseUrl}send-otp`, requestOptions)
//       .then((response) => {
//         if (!response.ok) {
//           setIsLoading(false); // Reset loading state on failure
//         }
//         return response.json();
//       })
//       .then((result) => {
//         if (result.status !== true) {
//           setError(result.message);
//           setIsLoading(false); // Reset loading state if error occurs
//           return;
//         }
//         localStorage.setItem("email", email); // Save email to localStorage
//         setStep(1); // Move to the next step (OTP verification)
//         setIsLoading(false); // Reset loading state after successful request
//       })
//       .catch((error) => {
//         setIsLoading(false); // Reset loading state on network error
//         setError("An error occurred. Please try again later.");
//       });
//   };

//   return (
//     <>
//       <div className="w-full flex">
//         <FormSideUi />
//         {step === 0 ? (
//           <div className="w-full flex-col items-center justify-center">
//             <div className={`min-h-screen  flex flex-col items-center justify-center`}>
//               <div className="flex sm:px-10 py-2 items-center lg:hidden">
           
//   <img className="h-14 md:h-20 absolute top-5 left-0 m-4" src={logo} alt="Logo" />

//   <br></br>
//   <br></br>
//                           <br></br>
//                 <div className={`text-2xl  md:text-4xl font-bold text-[${primaryColor}]`}>
//                   {/* YEHADIGITAL */}
//                 </div>
//               </div>
//               <div className="w-full flex  justify-center py-5">
//                 <div className="w-[90%] max-w-[600px] bg-white px-5 sm:px-10 py-5">
//                   <h1
//                     style={{ color: secondaryColor }}
//                     className={`text-2xl lg:text-4xl font-bold text-[${secondaryColor}] `}
//                   >
//                     Login with Email
//                   </h1>
//                   <h1
//                     style={{ color: secondaryColor }}
//                     className={`text-md text-[${secondaryColor}] py-1`}
//                   >
                
//                     Please enter your email address
//                   </h1>

//                   <div className=" flex justify-center space-y-3 text-lg text-slate-600 ">
//                     <div className="flex justify-center flex-col items-start w-full gap-2">
//                       <div className="flex items-start flex-col w-full gap-0">
//                         <input
//                           value={email}
//                           placeholder="Enter your email"
//                           onChange={(ev) => setEmail(ev.target.value)}
//                           className="border border-slate-600/50 rounded-md px-3 py-2 w-full"
//                         />
//                         <label className="text-red-500 text-sm">
//                           {emailError}
//                         </label>
//                       </div>

//                       <label className="text-red-500 text-sm text-center">{error}</label>

//                       {/* Terms and Conditions Checkbox (Always checked) */}
//                       <div className="flex items-center">
//                         <input
//                           type="checkbox"
//                           id="agree-terms"
//                           checked={true} // Always checked
//                           disabled={false} // Disabled so the user cannot uncheck
//                         />
//                         <label htmlFor="agree-terms" className="ml-2 text-sm text-slate-600">
//                           I agree to the Terms and Conditions.
//                         </label>
//                       </div>

//                       {/* MainButton will be enabled as the checkbox is always checked */}
//                       <MainButton
//                         label={"Continue"}
//                         onClick={onButtonClick}
//                         isLoading={isLoading}
//                       />
                      
                      
                     

//                       <div className="flex flex-col items-center w-full mt-2 gap-3">
//                           <div className="flex items-center gap-3">
//                             <span>Don't have an account? </span>
//                             <NavLink to="/sign-up" className="text-[#2cb4d5]">
//                               SignUp
//                             </NavLink>
//                           </div>
//                           <br></br>
//                           <br></br>
//                           <br></br>
//                           <br></br>
//                           <br></br>
//                           <br></br>
//                           <br></br>
                          
                          
//                           {/* <img className="h-15 md:h-20 absolute top-0 left-8 m-4" src="/visa.gif" alt="Logo" /> */}
//                           {/* Footer */}
//         <div className="w-full border-t border-gray-300 my-4"></div>
//         <div className="text-xs text-center text-gray-500">
//           <p>&copy; {new Date().getFullYear()} YehaCard. All rights reserved. Powered by PSI</p>
                        
//                       </div>
//                     </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : step === 1 ? (
//           <VerifyOtp />
//         ) : (
//           <></>
//         )}
//       </div>
//     </>
//   );
// };

// export default ForgetPassword;


import { useState, useEffect } from "react";
import { logo } from "../assets";
import { baseUrl, primaryColor, secondaryColor } from "../constants/constants";
import { MainButton } from "../components";
import { NavLink } from "react-router-dom";
import VerifyOtp from "./VerifyOtp";
import FormSideUi from "../components/formSideUi";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    // Define maintenance window
    const maintenanceStart = new Date("2024-11-17T00:00:00"); // Adjust the start time
    const maintenanceEnd = new Date(maintenanceStart.getTime() + 72 * 60 * 60 * 1000); // 72 hours later
    const now = new Date();

    setIsMaintenance(now >= maintenanceStart && now <= maintenanceEnd);
  }, []);

  const onButtonClick = () => {
    if (isLoading) return;

    setIsLoading(true);
    setEmailError("");
    setError("");

    if ("" === email) {
      setEmailError("The email field is required");
      setIsLoading(false);
      return;
    }

    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Please enter a valid email");
      setIsLoading(false);
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ email });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseUrl}send-otp`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
        }
        return response.json();
      })
      .then((result) => {
        if (result.status !== true) {
          setError(result.message);
          setIsLoading(false);
          return;
        }
        localStorage.setItem("email", email);
        setStep(1);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setError("An error occurred. Please try again later.");
      });
  };

  if (isMaintenance) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800">
            Our Card Provider is Under Maintenance Mode
          </h1>
          <p className="text-lg text-gray-600">
            This feature is temporarily unavailable due to maintenance. Please check back after{" "}
            <strong>11/14/2024</strong>.
          </p>
          <p className="text-sm text-gray-500">
            Thank you for your patience.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex">
        <FormSideUi />
        {step === 0 ? (
          <div className="w-full flex-col items-center justify-center">
            <div className={`min-h-screen flex flex-col items-center justify-center`}>
              <div className="flex sm:px-10 py-2 items-center lg:hidden">
                <img className="h-14 md:h-20 absolute top-5 left-0 m-4" src={logo} alt="Logo" />
                <div className={`text-2xl md:text-4xl font-bold text-[${primaryColor}]`}></div>
              </div>
              <div className="w-full flex justify-center py-5">
                <div className="w-[90%] max-w-[600px] bg-white px-5 sm:px-10 py-5">
                  <h1
                    style={{ color: secondaryColor }}
                    className={`text-2xl lg:text-4xl font-bold text-[${secondaryColor}] `}
                  >
                    Login with Email
                  </h1>
                  <h1
                    style={{ color: secondaryColor }}
                    className={`text-md text-[${secondaryColor}] py-1`}
                  >
                    Please enter your email address
                  </h1>
                  <div className="flex justify-center space-y-3 text-lg text-slate-600 ">
                    <div className="flex flex-col items-start w-full gap-2">
                      <div className="flex items-start flex-col w-full gap-0">
                        <input
                          value={email}
                          placeholder="Enter your email"
                          onChange={(ev) => setEmail(ev.target.value)}
                          className="border border-slate-600/50 rounded-md px-3 py-2 w-full"
                        />
                        <label className="text-red-500 text-sm">{emailError}</label>
                      </div>
                      <label className="text-red-500 text-sm text-center">{error}</label>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="agree-terms"
                          checked={true}
                          disabled={true}
                        />
                        <label htmlFor="agree-terms" className="ml-2 text-sm text-slate-600">
                          I agree to the Terms and Conditions.
                        </label>
                      </div>
                      <MainButton label={"Continue"} onClick={onButtonClick} isLoading={isLoading} />
                      <div className="flex items-center gap-3">
                        <span>Don't have an account?</span>
                        <NavLink to="/sign-up" className="text-[#2cb4d5]">
                          SignUp
                        </NavLink>
                      </div>
                      <div className="text-xs text-center text-gray-500">
                        <p>
                          &copy; {new Date().getFullYear()} YehaCard. All rights reserved. Powered by PSI.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : step === 1 ? (
          <VerifyOtp />
        ) : null}
      </div>
    </>
  );
};

export default ForgetPassword;
